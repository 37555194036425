import { FC, useEffect, useState } from 'react'
import { MdOutlineAddToHomeScreen } from 'react-icons/md'
import { InstallAppToast } from './InstallAppToast'
import { RoundButton } from '@components/ui'
import cn from 'clsx'
const { detect } = require('detect-browser')

interface Props {
  className?: string
  installUrl: string
  isProductPage?: boolean
}

const InstallAppIframe: FC<Props> = ({ installUrl, isProductPage }) => {
  const [browser, setBrowser] = useState<{ name: string }>()

  useEffect(() => {
    setBrowser(detect())
  }, [])

  const handleClick = () => {
    window.open(installUrl, '_blank', 'noreferrer')
  }

  return browser &&
    ['chrome', 'edge', 'crios', 'edge-ios', 'edge-chromium'].includes(
      browser.name
    ) ? (
    <div className={cn(`fixed bottom-${isProductPage ? 80 : 40
      } right-2 z-[1000] h-20 w-20`, { 'sm:right-[50.4%] md:right-[45.4%] xl:right-[40.4%]': isProductPage })}>
      <RoundButton
        onClick={handleClick}
        icon={MdOutlineAddToHomeScreen}
        ariaLabel="Open mobile app and install"
      />
      <InstallAppToast />
    </div>
  ) : null
}

export default InstallAppIframe
