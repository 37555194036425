import type { Page } from '@commerce/types/page'
import type { Category } from '@commerce/types/site'
import CartSidebarView from '@components/cart/CartSidebarView'
import { CheckoutProvider } from '@components/checkout/context'
import { Footer } from '@components/common'
import { Button, LoadingDots, Sidebar } from '@components/ui'
import { useUI } from '@components/ui/context'
import { CommerceProvider } from '@framework'
import { useAcceptCookies } from '@lib/sites/hooks/useAcceptCookies'
import cn from 'clsx'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { FC } from 'react'
import { Link } from '../UserNav/MenuSidebarView'
import LoginView from '@components/auth/LoginView'
import { isWatchModeIframe } from '@components/sites/product/helpers'
import { setApiConfig } from '@framework/cart'
import Basket from '../UserNav/Basket'
import BasketIframe from '../UserNav/BasketIframe'
import InstallApp from '../UserNav/InstallApp'
import InstallAppIframe from '../UserNav/InstallAppIframe'
import s from './Layout.module.css'

const Loading = () => (
  <div className="flex h-80 w-80 items-center justify-center p-3 text-center">
    <LoadingDots />
  </div>
)

const dynamicProps = {
  loading: Loading,
}

const SignUpView = dynamic(() => import('@components/auth/SignUpView'), {
  ...dynamicProps,
})

const ForgotPassword = dynamic(
  () => import('@components/auth/ForgotPassword'),
  {
    ...dynamicProps,
  }
)

const FeatureBar = dynamic(() => import('@components/common/FeatureBar'), {
  ...dynamicProps,
})

const Modal = dynamic(() => import('@components/ui/Modal'), {
  ...dynamicProps,
  ssr: false,
})

interface Props {
  pageProps: {
    pages?: Page[]
    categories: Category[]
    site: string
    apiUrl: string
    apiToken: string
    productDetailsAsStory: boolean
    product?: any
  }
}

const ModalView: FC<{ modalView: string; closeModal(): any }> = ({
  modalView,
  closeModal,
}) => {
  return (
    <Modal onClose={closeModal}>
      {modalView === 'LOGIN_VIEW' && <LoginView />}
      {modalView === 'SIGNUP_VIEW' && <SignUpView />}
      {modalView === 'FORGOT_VIEW' && <ForgotPassword />}
    </Modal>
  )
}

const ModalUI: FC = () => {
  const { displayModal, closeModal, modalView } = useUI()
  return displayModal ? (
    <ModalView modalView={modalView} closeModal={closeModal} />
  ) : null
}

const SidebarView: FC<{
  sidebarView: string
  closeSidebar(): any
  links: Link[]
}> = ({ sidebarView, closeSidebar, links }) => {
  return (
    <Sidebar onClose={closeSidebar}>
      {/* {sidebarView === 'MOBILEMENU_VIEW' && <MenuSidebarView links={links} />} */}
      {sidebarView === 'CART_VIEW' && <CartSidebarView />}
      {/* {sidebarView === 'CHECKOUT_VIEW' && <CheckoutSidebarView />}
      {sidebarView === 'PAYMENT_VIEW' && <PaymentMethodView />}
      {sidebarView === 'SHIPPING_VIEW' && <ShippingView />} */}
    </Sidebar>
  )
}

const SidebarUI: FC<{ links: any }> = ({ links }) => {
  const { displaySidebar, closeSidebar, sidebarView } = useUI()
  return displaySidebar ? (
    <SidebarView
      sidebarView={sidebarView}
      closeSidebar={closeSidebar}
      links={links}
    />
  ) : null
}

const Layout: FC<Props> = ({
  children,
  pageProps: { categories = [], site, ...pageProps },
}) => {
  const router = useRouter()
  const { acceptedCookies, onAcceptCookies } = useAcceptCookies()
  const { locale = 'en-US' } = useRouter()
  const navBarlinks = categories.slice(0, 2).map((c) => ({
    label: c.name,
    href: `/search/${c.slug}`,
  }))
  const isIframe = isWatchModeIframe()

  setApiConfig(pageProps.apiToken, pageProps.apiUrl)

  const showButtons = router && !router.asPath.startsWith('/install')
  const isProductPage = router && router.asPath.startsWith('/product')
  const showFooter = !(pageProps.productDetailsAsStory && pageProps.product)
  const showPrivacyPolicy = !(
    pageProps.productDetailsAsStory && pageProps.product
  )

  const storefrontBaseDomain =
    process.env.NEXT_PUBLIC_STAGE === 'prod'
      ? 'shopcat.store'
      : process.env.NEXT_PUBLIC_STAGE === 'staging'
      ? 'staging.shopcat.store'
      : 'dev.shopcat.store'

  return (
    <CommerceProvider
      locale={locale}
      // apiUrl={pageProps.apiUrl}
      // apiToken={pageProps.apiToken}
    >
      <div className={cn(s.root)}>
        {/* <Navbar links={navBarlinks} /> */}
        <main className="fit">{children}</main>
        {showFooter && <Footer pages={pageProps.pages} />}
        <ModalUI />
        <CheckoutProvider>
          <SidebarUI links={navBarlinks} />
        </CheckoutProvider>

        {showButtons && isIframe === false && (
          <Basket isProductPage={isProductPage} />
        )}
        {showButtons && isIframe === true && (
          <BasketIframe isProductPage={isProductPage} />
        )}

        {showButtons && isIframe === false && (
          <InstallApp isProductPage={isProductPage} />
        )}
        {showButtons && isIframe === true && (
          <InstallAppIframe
            installUrl={`https://${site}.${storefrontBaseDomain}/install`}
            isProductPage={isProductPage}
          />
        )}

        {isIframe === false && showPrivacyPolicy && (
          <FeatureBar
            title="This site uses cookies to improve your experience. By clicking, you agree to our Privacy Policy."
            hide={acceptedCookies}
            action={
              <Button className="mx-5" onClick={() => onAcceptCookies()}>
                Accept cookies
              </Button>
            }
          />
        )}
      </div>
    </CommerceProvider>
  )
}

export default Layout
