import type { Page } from '@commerce/types/page'
import { Shopcat } from '@components/icons'
import { Container } from '@components/ui'
import getSlug from '@lib/sites/get-slug'
import cn from 'clsx'
import { useRouter } from 'next/router'
import { FC } from 'react'
import s from './Footer.module.css'

interface Props {
  className?: string
  children?: any
  pages?: Page[]
}

const links = [
  {
    name: 'Home',
    url: '/',
  },
]

const Footer: FC<Props> = ({ className, pages }) => {
  const { sitePages } = usePages(pages)
  const rootClassName = cn(s.root, className)

  return (
    <footer className={`${rootClassName} h-[6.5rem]`}>
      <Container className="bg-gradient-background-to-t">
        <div className="flex flex-col items-center justify-between space-y-4 pt-6 pb-10 text-sm text-base-600 md:flex-row">
          <div>
            <span>
              &copy; 2022 All brand's copyrights belong to respective owners.
            </span>
          </div>
          {/* <I18nWidget /> */}
          <div className="flex items-center text-sm text-primary">
            <a
              rel="noopener noreferrer"
              href="https://shopcat.io"
              aria-label="Shopcat.io Link"
              target="_blank"
              className="text-primary"
            >
              <Shopcat
                className="ml-3 inline-block h-6 w-min text-primary"
                alt="Shopcat.io Logo"
              />
            </a>
          </div>
        </div>
      </Container>
    </footer>
  )
}

function usePages(pages?: Page[]) {
  const { locale } = useRouter()
  const sitePages: Page[] = []

  if (pages) {
    pages.forEach((page) => {
      const slug = page.url && getSlug(page.url)
      if (!slug) return
      if (locale && !slug.startsWith(`${locale}/`)) return
      sitePages.push(page)
    })
  }

  return {
    sitePages: sitePages.sort(bySortOrder),
  }
}

// Sort pages by the sort order assigned in the BC dashboard
function bySortOrder(a: Page, b: Page) {
  return (a.sort_order ?? 0) - (b.sort_order ?? 0)
}

export default Footer
